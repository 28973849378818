import "./App.css";
import logo from "./resources/FestULoggaVit.png";
import MapContainer from "./map/MapContainer";
import { kalas } from "./KalasInfo";
import Footer from "./Footer";
import { useState } from "react";

function App() {
  const [currentFloor, setCurrentFloor] = useState(1);

  function changeFloorTo(floor) {
    setCurrentFloor(floor);
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="FestU logo" className="festu-logo"></img>
        <p className="caps">{kalas}</p>
      </header>
      <MapContainer currentFloor={currentFloor} />
      <Footer currentFloor={currentFloor} changeFloorTo={changeFloorTo} />
    </div>
  );
}

export default App;
