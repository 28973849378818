export const kalas = "Test";
export const maxFloor = 2;
export const minFloor = 0;
export const imageWidth = 830;

export const Rooms = {
  Karres: "karres",
  Vinter: "vinter",
  Ja: "ja",
  Innergard: "innergard",
  ToalettKarres1: "toalettKarres1",
  ToalettKarres2: "toalettKarres2",
  ToalettGarderob: "toalettGarderob",
  ToalettStore: "toalettSrore",
  Spelhall: "spelhall",
  Saab: "saab",
  Volvo1: "volvo1",
  Volvo2: "volvo2",
  GPV: "gpv",
  GPM: "gpm",
  GPS: "gps",
  Scania: "scania",
  Post: "post",
  NCC: "ncc",
  Motionshall: "motionshall",
  Balkong: "balkong",
  Kyrkan: "kyrkan",
  Gasquen: "gasquen",
  ToalettGasquen: "toalettGasquen",
  Teknologgard: "teknologgard",
};

export const Activites = {
  Food: "food",
  Dance: "dance",
  Bar: "bar",
  Drinks: "drinks",
  BouncyCastle: "bouncycastle",
  Karaoke: "karaoke",
  SilentDisco: "silentdisco",
  PhotoStudio: "photostudio",
  Warderobe: "warderobe",
  Toilet: "toilet",
  AccessibleToilet: "accessible",
  Games: "games",
  TableTennis: "tabletennis",
  Coffee: "coffee",
  Consert: "consert",
  PoolTable: "pooltable",
};

export const roomInfo = new Map([
  [
    Rooms.Karres,
    {
      floor: 1,
      open: true,
      activities: [Activites.Consert],
      name: "Consert",
      description: "artist1: 23:00-00:00 | artist2: 00:00-01:00",
      w: 400,
      h: 300,
    },
  ],
  [
    Rooms.Vinter,
    {
      floor: 1,
      open: true,
      activities: [Activites.PhotoStudio],
      name: "",
      description: "Hallway with photo studio",
      w: 100,
      h: 600,
    },
  ],
  [
    Rooms.Ja,
    {
      floor: 1,
      open: true,
      activities: [Activites.Food, Activites.Bar],
      name: "J.A. Pripps",
      description: "Pub",
      w: 200,
      h: 250,
    },
  ],
  [
    Rooms.Innergard,
    {
      floor: 1,
      open: true,
      activities: [Activites.Coffee],
      name: "Outside area",
      description: "Free coffee",
      w: 300,
      h: 300,
      additional: { stairToGasquen: true },
    },
  ],
  [
    Rooms.Spelhall,
    {
      floor: 1,
      open: true,
      activities: [Activites.Games],
      name: "J.A. Spelhall",
      description: "Shuffle board, pool tabel etc.",
      w: 250,
      h: 120,
    },
  ],
  [
    Rooms.Saab,
    {
      floor: 1,
      open: true,
      activities: [Activites.Bar, Activites.Drinks, Activites.Food],
      name: "Entrance",
      description: "",
      w: 300,
      h: 400,
      additional: {
        stairToGasquen: true,
        stairToFloor2: true,
        corridor1: { w: 50, h: 275 },
        corridor2: { w: 175, h: 50 },
      },
    },
  ],
  [
    Rooms.Volvo1,
    {
      floor: 1,
      open: true,
      activities: [Activites.Warderobe],
      w: 200,
      h: 350,
      name: "Warderobe",
      description: "Free",
      additional: {
        stairToFloor2: true,
        corridor1: { w: 50, h: 75 },
        corridor2: { w: 150, h: 30 },
      },
    },
  ],
  [
    Rooms.Teknologgard,
    {
      floor: 1,
      open: true,
      activities: [Activites.Bar, Activites.Dance],
      name: "Outside area",
      description: "",
      w: 400,
      h: 800,
      additional: {
        corridor1: { w: 180, h: 675 },
        corridor2: { w: 120, h: 50 },
      },
    },
  ],
  [
    Rooms.Volvo2,
    {
      floor: 2,
      open: true,
      activities: [Activites.Dance, Activites.Bar],
      name: "Dancefloor",
      description: "",
      w: 300,
      h: 450,
      additional: { stairToFloor1: true },
    },
  ],
  [
    Rooms.GPV,
    {
      floor: 2,
      open: true,
      activities: [Activites.Karaoke],
      name: "Karaoke",
      description: "",
      w: 100,
      h: 100,
    },
  ],
  [
    Rooms.GPM,
    {
      floor: 2,
      open: true,
      activities: [Activites.Bar, Activites.Drinks],
      name: "",
      description: "",
      w: 100,
      h: 100,
    },
  ],
  [
    Rooms.GPS,
    {
      floor: 2,
      open: true,
      activities: [Activites.TableTennis],
      name: "Table tennis",
      description: "",
      w: 100,
      h: 100,
    },
  ],
  [
    Rooms.Scania,
    {
      floor: 2,
      open: true,
      activities: [Activites.Bar, Activites.Drinks],
      name: "Lounge",
      description: "",
      w: 300,
      h: 300,
      additional: { door1: true, door2: true },
    },
  ],
  [
    Rooms.Post,
    {
      floor: 2,
      open: true,
      activities: [],
      name: "",
      description: "Hallway with seating",
      w: 200,
      h: 300,
      additional: { corridor1: { w: 96, h: 302 } },
    },
  ],
  [
    Rooms.NCC,
    {
      floor: 2,
      open: true,
      activities: [Activites.Dance, Activites.Bar],
      name: "Dancefloor",
      description: "",
      w: 300,
      h: 400,
      additional: { stairToFloor1: true, corridor1: { w: 100, h: 100 } },
    },
  ],
  [
    Rooms.Motionshall,
    {
      floor: 2,
      open: true,
      activities: [Activites.BouncyCastle],
      name: "Bouncy Castle",
      description: "",
      w: 300,
      h: 200,
    },
  ],
  [
    Rooms.Balkong,
    {
      floor: 2,
      open: true,
      activities: [],
      name: "Balcony",
      description: "",
      w: 550,
      h: 70,
      additional: { corridor1: { w: 70, h: 200 } },
    },
  ],
  [
    Rooms.Kyrkan,
    {
      floor: 2,
      open: true,
      activities: [
        Activites.Bar,
        Activites.Drinks,
        Activites.PoolTable,
        Activites.Consert,
      ],
      name: "Jazz lounge",
      description: "with live music 23:00-01:00",
      w: 300,
      h: 550,
      additional: { stairToFloor1: true, corridor1: { w: 250, h: 50 } },
    },
  ],
  [
    Rooms.ToalettKarres1,
    {
      floor: 1,
      open: true,
      activities: [Activites.Toilet],
      name: "Toilets",
      description: "",
      w: 100,
      h: 100,
    },
  ],
  [
    Rooms.ToalettKarres2,
    {
      floor: 1,
      open: true,
      activities: [Activites.Toilet],
      name: "Toilets",
      description: "",
      w: 100,
      h: 100,
    },
  ],
  [
    Rooms.ToalettGarderob,
    {
      floor: 1,
      open: true,
      name: "Toilets",
      activities: [Activites.Toilet, Activites.AccessibleToilet],
      description: "",
      w: 200,
      h: 100,
    },
  ],
  [
    Rooms.ToalettStore,
    {
      floor: 1,
      open: true,
      name: "Toilets",
      activities: [Activites.Toilet],
      description: "",
      w: 200,
      h: 100,
    },
  ],
  [
    Rooms.Gasquen,
    {
      floor: 1,
      open: true,
      name: "Gasquen",
      activities: [Activites.Dance, Activites.Bar],
      description: "",
      w: 300,
      h: 500,
      additional: {
        stairToFloor1: true,
        stairToInnergard: true,
        corridor1: { w: 100, h: 300 },
        corridor2: { w: 100, h: 200 },
      },
    },
  ],
  [
    Rooms.ToalettGasquen,
    {
      floor: 1,
      open: true,
      name: "Toilets",
      activities: [Activites.Toilet],
      description: "",
      w: 100,
      h: 200,
    },
  ],
]);
