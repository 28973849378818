import { Activites, roomInfo } from "../KalasInfo";
import { RoomDiv, StairDiv } from "../StyledComponents";
import "./room.css";

/**
 *
 * @param {Rooms} roomName name of the room
 * @param {{Number, Number}} wh
 * @param {Number} left
 * @param {Number} top
 * @param {JSX.Element} additionalElement
 * @returns room element
 */
export const Room = ({ roomName, left, top, additionalElement }) => {
  const room = roomInfo.get(roomName);
  const color = room.open ? "#95a5a6" : "#ccc";

  const icons = (
    <div>
      {room.activities.some((activity) => activity === Activites.Food) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          lunch_dining
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Dance) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          nightlife
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Bar) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          sports_bar
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Drinks) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          local_bar
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Toilet) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          wc
        </span>
      )}
      {room.activities.some(
        (activity) => activity === Activites.AccessibleToilet
      ) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          accessible
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Warderobe) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          checkroom
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Karaoke) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          mic_external_on
        </span>
      )}
      {room.activities.some(
        (activity) => activity === Activites.SilentDisco
      ) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          headphones
        </span>
      )}
      {room.activities.some(
        (activity) => activity === Activites.TableTennis
      ) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          sports_tennis
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Games) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          joystick
        </span>
      )}
      {room.activities.some(
        (activity) => activity === Activites.PhotoStudio
      ) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          photo_camera
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Coffee) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          coffee
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.Consert) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          artist
        </span>
      )}
      {room.activities.some((activity) => activity === Activites.PoolTable) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          table_restaurant
        </span>
      )}
      {room.activities.some(
        (activity) => activity === Activites.BouncyCastle
      ) && (
        <span
          className="material-symbols-outlined"
          style={{ margin: "0px 8px", color: "var(--mapBackground)" }}
        >
          fort
        </span>
      )}
    </div>
  );

  return (
    <RoomDiv
      w={room.w}
      h={room.h}
      left={left}
      top={top}
      color={"var(--mapColor)"}
      className={roomName}
    >
      <h3 className="room-name">{room.name}</h3>
      {room.description !== "" && <p>{room.description}</p>}
      {room.activities.length > 0 && icons}
      {additionalElement}
    </RoomDiv>
  );
};

/**
 *
 * @param {String} text Text to show in stair element
 * @param {Number} top Pixels from top of parent element
 * @param {Number} left Pixels from left side of parant element
 * @returns JSX.Element representing stairs
 */
export const Stair = ({ text, top, left }) => {
  return (
    <StairDiv top={top} left={left}>
      {text}
      <span className="material-symbols-outlined">stairs</span>
    </StairDiv>
  );
};
