import { Room, Stair } from "./room";
import { Rooms, roomInfo } from "../KalasInfo";
import { CorridorDiv } from "../StyledComponents";

/**
 *
 * @returns Map of floor 1
 */
const Floor1 = () => {
  //#region Additional elements
  const saabAdditonalsArray = roomInfo.get(Rooms.Saab).additional;
  /**
   * JSX element of additonals in saab
   */
  const saabAdditionals = (
    <>
      <CorridorDiv
        w={saabAdditonalsArray.corridor1.w}
        h={saabAdditonalsArray.corridor1.h}
        top={roomInfo.get(Rooms.Saab).h - 10}
        left={roomInfo.get(Rooms.Saab).w - 54}
      />
      <CorridorDiv
        w={saabAdditonalsArray.corridor2.w}
        h={saabAdditonalsArray.corridor2.h}
        top={
          roomInfo.get(Rooms.Saab).h +
          saabAdditonalsArray.corridor1.h -
          20 -
          saabAdditonalsArray.corridor2.h
        }
        left={
          roomInfo.get(Rooms.Saab).w -
          saabAdditonalsArray.corridor1.w -
          saabAdditonalsArray.corridor2.w
        }
      />
      {saabAdditonalsArray.stairToGasquen && (
        <Stair text={"Down"} left={0} top={75} />
      )}
      {saabAdditonalsArray.stairToFloor2 && (
        <Stair text={"Up"} left={roomInfo.get(Rooms.Saab).w - 50} top={200} />
      )}
    </>
  );

  const innergardAdditonalsArray = roomInfo.get(Rooms.Saab).additional;

  /**
   * JSX element of additonals in innergarden
   */
  const innergardAdditionals = (
    <>
      {innergardAdditonalsArray.stairToGasquen && (
        <Stair text={"Down"} left={0} top={0} />
      )}
    </>
  );

  const volvo1AdditonalsArray = roomInfo.get(Rooms.Volvo1).additional;

  /**
   * JSX element of additonals in volvo 1
   */
  const volvo1Additionals = (
    <>
      <CorridorDiv
        w={volvo1AdditonalsArray.corridor1.w}
        h={volvo1AdditonalsArray.corridor1.h}
        top={-74}
        left={0}
      />
      <CorridorDiv
        w={volvo1AdditonalsArray.corridor2.w}
        h={volvo1AdditonalsArray.corridor2.h}
        top={-74}
        left={49}
      />
      {volvo1AdditonalsArray.stairToFloor2 && (
        <Stair text={"Up"} left={0} top={100} />
      )}
    </>
  );

  const teknologgardAdditonalsArray = roomInfo.get(
    Rooms.Teknologgard
  ).additional;

  /**
   * JSX element of additonals in teknologgarden
   */
  const teknologgardAdditionals = (
    <>
      <CorridorDiv
        w={teknologgardAdditonalsArray.corridor1.w}
        h={teknologgardAdditonalsArray.corridor1.h}
        top={-670}
        left={0}
        className={Rooms.Teknologgard}
      />
      <CorridorDiv
        w={teknologgardAdditonalsArray.corridor2.w}
        h={teknologgardAdditonalsArray.corridor2.h}
        top={-670}
        left={175}
        className={Rooms.Teknologgard}
      />
    </>
  );

  //#endregion

  //#region Room sizes and coordinates
  /**
   * TODO: Remove
   */
  const toilet = {
    w: roomInfo.get(Rooms.ToalettKarres1).w,
    h: roomInfo.get(Rooms.ToalettKarres1).h,
  };
  const karres = {
    w: roomInfo.get(Rooms.Karres).w,
    h: roomInfo.get(Rooms.Karres).h,
  };
  const innergard = {
    w: roomInfo.get(Rooms.Innergard).w,
    h: roomInfo.get(Rooms.Innergard).h,
  };
  const vintertradgard = {
    w: roomInfo.get(Rooms.Vinter).w,
    h: roomInfo.get(Rooms.Vinter).h,
  };
  const ja = { w: roomInfo.get(Rooms.Ja).w, h: roomInfo.get(Rooms.Ja).h };
  const saab = {
    w: roomInfo.get(Rooms.Saab).w,
    h:
      roomInfo.get(Rooms.Saab).h +
      roomInfo.get(Rooms.Saab).additional.corridor1.h,
  };
  const volvo1 = {
    w: roomInfo.get(Rooms.Volvo1).w,
    h:
      roomInfo.get(Rooms.Volvo1).h -
      roomInfo.get(Rooms.Volvo1).additional.corridor1.h,
  };

  // const karresCoord = {left:toilet.w, top:0}
  // const toilet1 ={left: 0, top:karres.h+50}
  // const toilet2 = {left:0, top: toilet1.top+toilet.w+25}
  // const vintertradgardCoord = {left: toilet.w+toilet1.left, top:karresCoord.top + karres.h}
  // const innergardCoord = {left: vintertradgardCoord.left + vintertradgard.w, top:karresCoord.top + karres.h}
  // const jaCoord = {left: vintertradgardCoord.left + vintertradgard.w, top: innergardCoord.top + innergard.h}
  // const saabCoord = {left:vintertradgardCoord.left + vintertradgard.w ,top: jaCoord.top + ja.h}

  //#endregion

  return (
    <>
      {roomInfo.get(Rooms.Karres).open && (
        <Room roomName={Rooms.Karres} wh={karres} left={toilet.w} top={0} />
      )}
      {roomInfo.get(Rooms.Innergard).open && (
        <Room
          roomName={Rooms.Innergard}
          left={toilet.w + vintertradgard.w}
          top={karres.h}
          additionalElement={innergardAdditionals}
        />
      )}
      {roomInfo.get(Rooms.Vinter).open && (
        <Room roomName={Rooms.Vinter} top={karres.h} left={toilet.w} />
      )}
      {roomInfo.get(Rooms.Ja).open && (
        <Room
          roomName={Rooms.Ja}
          left={toilet.w + vintertradgard.w}
          top={karres.h + innergard.h}
        />
      )}
      {roomInfo.get(Rooms.Spelhall).open && (
        <Room
          roomName={Rooms.Spelhall}
          left={toilet.w + vintertradgard.w + saab.w}
          top={karres.h + innergard.h + ja.h}
        />
      )}
      {roomInfo.get(Rooms.Saab).open && (
        <Room
          roomName={Rooms.Saab}
          left={toilet.w + vintertradgard.w}
          top={karres.h + innergard.h + ja.h}
          additionalElement={saabAdditionals}
        />
      )}
      {roomInfo.get(Rooms.ToalettKarres1).open && (
        <Room roomName={Rooms.ToalettKarres1} left={0} top={karres.h + 50} />
      )}
      {roomInfo.get(Rooms.ToalettKarres2).open && (
        <Room roomName={Rooms.ToalettKarres2} left={0} top={karres.h + 175} />
      )}
      {roomInfo.get(Rooms.Volvo1).open && (
        <Room
          roomName={Rooms.Volvo1}
          left={toilet.w + vintertradgard.w + saab.w}
          top={karres.h + innergard.h + ja.h + saab.h - 100}
          additionalElement={volvo1Additionals}
        />
      )}
      {roomInfo.get(Rooms.ToalettGarderob).open && (
        <Room
          roomName={Rooms.ToalettGarderob}
          left={toilet.w + vintertradgard.w + saab.w + 100}
          top={
            karres.h +
            innergard.h +
            ja.h +
            saab.h -
            100 -
            roomInfo.get(Rooms.Volvo1).additional.corridor1.h -
            roomInfo.get(Rooms.ToalettGarderob).h
          }
        />
      )}
      {roomInfo.get(Rooms.ToalettStore).open && (
        <Room
          roomName={Rooms.ToalettStore}
          left={toilet.w + vintertradgard.w}
          top={karres.h + innergard.h + ja.h + saab.h - 15}
        />
      )}
      {roomInfo.get(Rooms.Teknologgard).open && (
        <Room
          roomName={Rooms.Teknologgard}
          left={-200}
          top={karres.h + innergard.h + ja.h + 60}
          additionalElement={teknologgardAdditionals}
        />
      )}
    </>
  );
};

export default Floor1;
