import { Room, Stair } from "./room";
import { Rooms, roomInfo } from "../KalasInfo";
import { CorridorDiv, HorizDoorDiv, VertDoorDiv } from "../StyledComponents";

/**
 *
 * @returns Map of floor 2
 */
const Floor2 = () => {
  //#region Additional elements

  // const nccAdditonalsArray = roomInfo.get(Rooms.NCC).additional;
  // /**
  //  * JSX element of additonals in innergarden
  //  */
  // const nccAdditionals = (
  //   <>
  //     {nccAdditonalsArray.stairToFloor1 && (
  //       <Stair text={"Down"} left={0} top={0} />
  //     )}
  //   </>
  // );

  const kyrkan = {
    w:
      roomInfo.get(Rooms.Kyrkan).w +
      roomInfo.get(Rooms.Kyrkan).additional.corridor1.w,
    h: roomInfo.get(Rooms.Kyrkan).h,
  };
  const ncc = {
    w: roomInfo.get(Rooms.NCC).w,
    h: roomInfo.get(Rooms.NCC).h,
  };
  const scania = {
    w: roomInfo.get(Rooms.Scania).w,
    h: roomInfo.get(Rooms.Scania).h,
  };
  const post = {
    w: roomInfo.get(Rooms.Post).w,
    h: roomInfo.get(Rooms.Post).h,
  };

  const gpx = {
    w: roomInfo.get(Rooms.GPM).w,
    h: roomInfo.get(Rooms.GPM).h,
  };

  const balkong = {
    w: roomInfo.get(Rooms.Balkong).w,
    h: roomInfo.get(Rooms.Balkong).h,
  };

  const kyrkanAdditionalsArray = roomInfo.get(Rooms.Kyrkan).additional;
  const kyrkanAdditionals = (
    <>
      <CorridorDiv
        w={kyrkanAdditionalsArray.corridor1.w}
        h={kyrkanAdditionalsArray.corridor1.h}
        top={450}
        left={roomInfo.get(Rooms.Kyrkan).w - 4}
      />
      {kyrkanAdditionalsArray.stairToFloor1 && (
        <Stair text={"Down"} left={250} top={350} />
      )}
      <VertDoorDiv
        top={110}
        left={kyrkan.w - kyrkanAdditionalsArray.corridor1.w - 20}
      />
      <VertDoorDiv top={kyrkan.h - 95} left={kyrkan.w - 15} />
    </>
  );

  const nccAdditionalsArray = roomInfo.get(Rooms.NCC).additional;
  const nccAdditionals = (
    <>
      <CorridorDiv
        w={nccAdditionalsArray.corridor1.w}
        h={nccAdditionalsArray.corridor1.h}
        top={-nccAdditionalsArray.corridor1.h + 3}
        left={ncc.w - nccAdditionalsArray.corridor1.w - 3}
      />
      {nccAdditionalsArray.stairToFloor1 && (
        <Stair text={"Down"} left={250} top={ncc.h - 100} />
      )}
    </>
  );

  const postAdditionalsArray = roomInfo.get(Rooms.Post).additional;
  const postAdditionals = (
    <>
      <CorridorDiv
        w={postAdditionalsArray.corridor1.w}
        h={postAdditionalsArray.corridor1.h}
        top={-postAdditionalsArray.corridor1.h + 3}
        left={post.w - postAdditionalsArray.corridor1.w - 3}
      />
      <HorizDoorDiv
        top={-postAdditionalsArray.corridor1.h - 10}
        left={post.w - 70}
      />
    </>
  );

  const scnaiaAdditionalsArray = roomInfo.get(Rooms.Scania).additional;
  const scaniaAdditionals = (
    <>
      {scnaiaAdditionalsArray.door1 && (
        <HorizDoorDiv top={scania.h - 10} left={scania.w - 70} />
      )}
      {scnaiaAdditionalsArray.door2 && (
        <HorizDoorDiv top={-10} left={scania.w - 70} />
      )}
    </>
  );

  const gpxAdditionals = (
    <>
      <VertDoorDiv top={30} left={gpx.w - 10} />
    </>
  );

  const volvo2AdditionalsArray = roomInfo.get(Rooms.Volvo2).additional;
  const volvo2Additionals = (
    <>
      {volvo2AdditionalsArray.stairToFloor1 && (
        <Stair text={"Down"} left={0} top={100} />
      )}
      <VertDoorDiv top={30} left={-10} />
    </>
  );

  const balkongAdditionalsArray = roomInfo.get(Rooms.Balkong).additional;
  const balkongAdditionals = (
    <>
      <CorridorDiv
        w={balkongAdditionalsArray.corridor1.w}
        h={balkongAdditionalsArray.corridor1.h}
        top={-balkongAdditionalsArray.corridor1.h + 3}
        left={0}
        className={Rooms.Balkong}
      />
      <HorizDoorDiv top={balkong.h - 5} left={balkong.w - 70} />
    </>
  );

  const motionAdditionals = (
    <>
      <VertDoorDiv top={80} left={-10} />
    </>
  );

  //#endregion

  //#region Room sizes and coordinates
  /**
   * TODO: Remove
   */

  //#endregion

  return (
    <>
      <Room
        roomName={Rooms.Kyrkan}
        top={0}
        left={0}
        additionalElement={kyrkanAdditionals}
      />
      <Room
        roomName={Rooms.NCC}
        top={kyrkan.h - 100}
        left={kyrkan.w}
        additionalElement={nccAdditionals}
      />
      <Room
        roomName={Rooms.Motionshall}
        top={kyrkan.h - 130}
        left={kyrkan.w + ncc.w}
        additionalElement={motionAdditionals}
      />
      <Room
        roomName={Rooms.Scania}
        top={kyrkan.h + ncc.h - 100}
        left={kyrkan.w - 150}
        additionalElement={scaniaAdditionals}
      />
      <Room
        roomName={Rooms.Post}
        top={kyrkan.h + ncc.h + scania.h - 100}
        left={kyrkan.w + 50}
        additionalElement={postAdditionals}
      />
      <Room
        roomName={Rooms.GPS}
        top={kyrkan.h + ncc.h + scania.h - 100}
        left={kyrkan.w - 50}
        additionalElement={gpxAdditionals}
      />
      <Room
        roomName={Rooms.GPM}
        top={kyrkan.h + ncc.h + scania.h}
        left={kyrkan.w - 50}
        additionalElement={gpxAdditionals}
      />
      <Room
        roomName={Rooms.GPV}
        top={kyrkan.h + ncc.h + scania.h + 100}
        left={kyrkan.w - 50}
        additionalElement={gpxAdditionals}
      />
      <Room
        roomName={Rooms.Volvo2}
        top={kyrkan.h + ncc.h + scania.h + 100}
        left={kyrkan.w + post.w + 50}
        additionalElement={volvo2Additionals}
      />
      <Room
        roomName={Rooms.Balkong}
        top={283}
        left={kyrkan.w - 250}
        additionalElement={balkongAdditionals}
      />
    </>
  );
};

export default Floor2;
