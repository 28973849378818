import { Room, Stair } from "./room";
import { Rooms, roomInfo } from "../KalasInfo";
import { CorridorDiv } from "../StyledComponents";

/**
 *
 * @returns map of floor 0
 */
const Floor0 = () => {
  //#region Additional elements
  const gasquenAdditonalsArray = roomInfo.get(Rooms.Gasquen).additional;

  /**
   * JSX element of additonals in gasquen
   */
  const gasquenAdditionals = (
    <>
      <CorridorDiv
        w={gasquenAdditonalsArray.corridor1.w}
        h={gasquenAdditonalsArray.corridor1.h}
        top={-297}
        left={150}
      />
      <CorridorDiv
        w={gasquenAdditonalsArray.corridor2.w}
        h={gasquenAdditonalsArray.corridor2.h}
        top={400}
        left={296}
      />
      {gasquenAdditonalsArray.stairToFloor1 && (
        <Stair text={"Up"} left={320} top={502} />
      )}
      {gasquenAdditonalsArray.stairToInnergard && (
        <Stair text={"Up"} left={175} top={-300} />
      )}
    </>
  );

  //#endregion

  return (
    <>
      <Room
        roomName={Rooms.Gasquen}
        left={0}
        top={400}
        additionalElement={gasquenAdditionals}
      />
      <Room roomName={Rooms.ToalettGasquen} left={253} top={150} />
    </>
  );
};

export default Floor0;
