import React, { useState } from "react";
import "./MapContainer.css";
import Floor1 from "./Floor1";
import Floor0 from "./Floor0";
import Floor2 from "./Floor2";

/**
 *
 * @param {Number} currentFloor
 * @returns Interactive map of current floor
 */
const MapContainer = (currentFloor) => {
  // ============= Hooks =========
  const [mapPosition, setMapPosition] = useState({ x: 0, y: -200 });
  const [zoomLevel, setZoomLevel] = useState(0.3);
  const [dragStart, setDragStart] = useState(null);

  // ============= Mouse event handlers =========

  /**
   * Sets zoom level according to wheel event
   * @param {WheelEvent.deltaY} delta
   */
  const handleZoom = (delta) => {
    setZoomLevel((prevZoom) =>
      Math.min(2, Math.max(0.2, prevZoom - delta / 1000))
    );
  };

  /**
   * Triggers zoom on map
   * @param {WheelEvent} e
   */
  const handleWheel = (e) => {
    handleZoom(e.deltaY);
  };

  const handleMouseDown = (e) => {
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (dragStart) {
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;
      setMapPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragStart(null);
  };

  const handleMouseLeave = () => {
    setDragStart(null);
  };

  // ============= Touch event handlers =========

  /**
   * Handles start of drag movement over map
   * @param {TouchEvent} e
   */
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const [touch1, touch2] = e.touches;
      const initialDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );
      e.currentTarget.initialDistance = initialDistance;
    } else if (e.touches.length === 1) {
      const touch = e.touches[0];
      setDragStart({ x: touch.clientX, y: touch.clientY });
    }
  };

  /**
   *
   * @param {TouchEvent} e
   */
  const handleTouchMove = (e) => {
    // Two fingers
    if (e.touches.length === 2) {
      const [touch1, touch2] = e.touches;
      const currentDistance = Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );
      const delta = e.currentTarget.initialDistance - currentDistance;
      handleZoom(delta);
    }
    // One finger
    else if (e.touches.length === 1 && dragStart) {
      const touch = e.touches[0];
      const deltaX = touch.clientX - dragStart.x;
      const deltaY = touch.clientY - dragStart.y;
      setMapPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));
      setDragStart({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleTouchEnd = () => {
    setDragStart(null);
  };

  return (
    <div
      className={`Map-container`}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="Map"
        style={{
          transform: `scale(${zoomLevel}) translate(${mapPosition.x}px, ${mapPosition.y}px)`,
        }}
      >
        {currentFloor.currentFloor === 0 && <Floor0 />}
        {currentFloor.currentFloor === 1 && <Floor1 />}
        {currentFloor.currentFloor === 2 && <Floor2 />}
      </div>
    </div>
  );
};

export default MapContainer;
