import styled from "styled-components";

/**
 * Custom styled div for creating rooms
 */
export const RoomDiv = styled.div`
  position: absolute;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  background: var(--mapColor);
  border: 2px solid var(--mapBackground);
  color: var(--mapBackground);
  margin: 0px;
  text-align: center;
  padding: 10px;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

/**
 * Custom styled div for creating stairs
 */
export const StairDiv = styled.div`
  position: absolute;
  height: 100px;
  width: 50px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--mapBackground);
  color: var(--mapBackground);
`;

/**
 * Custom styled div for creating stairs
 */
export const CorridorDiv = styled.div`
  position: absolute;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  background: var(--mapColor);
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  margin: 0px;
`;

export const VertDoorDiv = styled.div`
  position: absolute;
  width: 20px;
  height: 40px;
  background: var(--mapColor);
  border: 0px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  margin: 0px;
  z-index: 1;
`;

export const HorizDoorDiv = styled.div`
  position: absolute;
  width: 40px;
  height: 20px;
  background: var(--mapColor);
  border: 0px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  margin: 0px;
  z-index: 1;
`;
