import { useState } from "react";
import { maxFloor, minFloor } from "./KalasInfo";
import "./Footer.css";
const Footer = ({ currentFloor, changeFloorTo }) => {
  const [floorInfoIcon, setFloorInfoIcon] = useState("expand_more");

  // Change every party
  const floors = new Map([
    [
      0,
      {
        bars: ["Gasquen bar"],
        dancefloors: ["Gasquen"],
        activities: [],
        food: false,
        toilets: true,
      },
    ],
    [
      1,
      {
        bars: ["J.A. Pripps", "Beer & cider bar", "Outdoor bar"],
        dancefloors: ["Techno laser tent"],
        activities: [
          "Coffee with JämK",
          "Boule",
          "Shuffle Board",
          "Pool Table",
        ],
        food: true,
        toilets: true,
        warderobe: true,
      },
    ],
    [
      2,
      {
        bars: ["Barbie bar", "Spooky bar", "Fairy bar", "Jazz prosecco bar"],
        dancefloors: ["Spooky - techno", "Fairy - Pop"],
        activities: ["Table tennis", "Bouncy castle", "Live jazz band"],
        food: false,
        toilets: false,
      },
    ],
  ]);

  function toggleFloorInfo() {
    let contents = document.getElementById("floor-info-contents");
    contents.classList.toggle("show");
    if (contents.classList.contains("show")) {
      setFloorInfoIcon("expand_more");
    } else {
      setFloorInfoIcon("expand_less");
    }
  }

  function increaseFloor() {
    if (currentFloor < maxFloor) {
      changeFloorTo(currentFloor + 1);
    }
  }

  function decreaseFloor() {
    if (currentFloor > minFloor) {
      changeFloorTo(currentFloor - 1);
    }
  }

  function createSpan(array) {
    if (array.length === 0) {
      return <p className="contents"> none </p>;
    } else if (array.length === 1) {
      return <p className="contents"> {array[0]}</p>;
    }

    const lastElement = array.pop();

    let element = (
      <p className="contents">
        {array.map((x) => (
          <span key={x}>{x} | </span>
        ))}{" "}
        <span key={lastElement}>{lastElement}</span>
      </p>
    );

    return element;
  }

  function floorIcons() {
    const floor = floors.get(currentFloor);
    return (
      <div>
        {floor.food && (
          <span
            className="material-symbols-outlined"
            style={{ margin: "0px 8px" }}
          >
            lunch_dining
          </span>
        )}
        {floor.toilets && (
          <>
            <span
              className="material-symbols-outlined"
              style={{ margin: "0px 0px 0px 8px" }}
            >
              wc
            </span>
            <span
              className="material-symbols-outlined"
              style={{ margin: "0px 8px 0px 0px" }}
            >
              accessible
            </span>
          </>
        )}
        {floor.warderobe && (
          <span
            className="material-symbols-outlined"
            style={{ margin: "0px 8px" }}
          >
            checkroom
          </span>
        )}
      </div>
    );
  }

  const text_element = (
    <div>
      <p className="title">BARS</p>
      {createSpan(floors.get(currentFloor).bars)}
      <p className="title">DANCEFLOORS</p>
      {createSpan(floors.get(currentFloor).dancefloors)}
      <p className="title">ACTIVITIES</p>
      {createSpan(floors.get(currentFloor).activities)}
      {floorIcons()}
    </div>
  );

  /**
   *
   * @param {MouseEvent} e
   */
  const handleMouseMove = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="footer" onMouseMove={handleMouseMove}>
      {currentFloor > minFloor ? (
        <div className="changeFloorButton" onClick={decreaseFloor}>
          <span className="material-symbols-outlined">chevron_left</span>
        </div>
      ) : (
        <div className="changeFloorButton"></div>
      )}
      <div className="floor-info-wrapper">
        <div className="floor-info" onClick={toggleFloorInfo}>
          <span className="material-symbols-outlined floorInfoIcon">
            {floorInfoIcon}
          </span>
          <p>Floor {currentFloor}</p>
        </div>

        <div id="floor-info-contents" className="show">
          {text_element}
        </div>
      </div>
      {currentFloor < maxFloor ? (
        <div className="changeFloorButton" onClick={increaseFloor}>
          <span className="material-symbols-outlined">chevron_right</span>
        </div>
      ) : (
        <div className="changeFloorButton"></div>
      )}
    </div>
  );
};

export default Footer;
